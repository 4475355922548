<template>
  <div
    class="login-page h-100 justify-content-center d-flex align-items-center"
  >
    <v-sheet
      v-if="!loading"
      elevation="12"
      max-width="600"
      rounded="lg"
      width="100%"
      class="pa-4 text-center mx-auto"
    >
      <v-icon v-if="isVerified" class="mb-5" color="success" size="112"
        >mdi-check-circle</v-icon
      >
      <v-icon v-else class="mb-5" color="error" size="112">
        mdi-alert-circle
      </v-icon>

      <h2 class="text-h5 mb-6">
        {{ isVerified ? "E-Posta Doğrulandı" : "E-Posta Doğrulanamadı" }}
      </h2>

      <p class="mb-4 text-medium-emphasis text-body-2">
        {{ message }}
      </p>

      <br />
      <p v-if="isUserRegistered" class="mb-4 text-medium-emphasis text-body-2">
        Eğer yönlendirilmesseniz anasayfaya dönmek için
        <router-link to="/">buraya</router-link> tıklayın.
      </p>
    </v-sheet>
    <div
      v-else
      style="height: 250px"
      class="d-flex flex-column justify-content-center align-items-center"
    >
      <v-progress-circular
        indeterminate
        color="primary"
        :size="70"
        width="7"
      ></v-progress-circular>
      <h3 class="mt-5">Lütfen bekleyiniz...</h3>
    </div>
  </div>
</template>

<script>
export default {
  name: "VerifyEmail",
  data() {
    return {
      isVerified: null,
      isUserRegistered: false,
      loading: true,
      message: "",
    };
  },
  async beforeMount() {
    const { email, token } = this.$route.query;
    try {
      const { message, is_user_registered } = await this.verifyEmailRequest(
        email,
        token
      );
      this.message = message;
      this.isUserRegistered = is_user_registered;
      this.isVerified = true;
    } catch (error) {
      this.isVerified = false;
      this.message = error[0].detail;
    } finally {
      this.loading = false;
    }
  },
  methods: {
    verifyEmailRequest(email, token) {
      return new Promise((resolve, reject) => {
        this.$apiService
          .post("email/check-verify", {
            email,
            token,
          })
          .then(
            ({ data }) => {
              resolve(data);
            },
            ({ response }) => {
              reject(response.data.errors);
            }
          );
      });
    },
  },
  watch: {
    isUserRegistered() {
      if (this.isUserRegistered) {
        setTimeout(() => {
          this.$router.push("/");
        }, 5000);
      }
    },
  },
};
</script>
